.scroll_to_top {
  opacity: 0;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 30px;
  right: 32px;
  width: 32px;
  height: 32px;
  border: 2px solid #f2f5f8;
  border-radius: 10px;
  background: #00cae8;
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
.scroll_to_top:hover {
  border: 1px solid transparent;
  background-color: #00cae8;
  color: #fff;
}
.scroll_to_top.show {
  opacity: 1 !important;
  visibility: visible;
}
