.popup_container {
  display: flex;
  flex-direction: column;
  z-index: 9999;
  position: absolute;
  top: 60px;
  right: 200px;
  width: 800px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 32px -8px rgba(71,71,71,0.6);
  overflow: hidden;
}
.popup_footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px 16px;
  background-color: #474747;
}
.popup_footer button {
  padding: 4px 10px;
  border: 0;
  font-size: 14px;
  border-radius: 2px;
}

@media only screen and (max-width: 991.98px) {
  .popup_container {
    left: 16px;
    right: 16px;
    width: calc(100vw - 32px);
  }
}