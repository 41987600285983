.quick_menu {
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* z-index: 1000; */
  position: fixed;
  bottom: 14vh;
  right: 20px;
}
.quick_menu li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #474747;
  transform: scale(0.9);
  transition: transform 0.3s ease-in-out;
  color: #fff;
  font-size: 30px;
}
.quick_menu li a:hover {
  transform: scale(1);
}

@media only screen and (max-width: 767.98px) {
  .quick_menu {
    bottom: 10vh;
  }
}
