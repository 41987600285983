@charset "UTF-8";

@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

.rotate_ring {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-left-color: rgba(200, 200, 200, 0.4);
  background: url(./images/spinner.png) no-repeat center;
  animation: rotate 1s infinite linear;
  animation-play-state: running;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
